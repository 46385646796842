<template>
  <b-container class="overflow-auto" fluid>
    <!-- Ad Interface controls -->
    <h5 class="my-3"><strong>POP FEEDS</strong></h5>
    <a-row class="my-2" :gutter="[8]">
      <a-col :xl="3" :md="6" :xs="12">
        <a-checkbox v-model="showActive">
          Show only active
        </a-checkbox>
      </a-col>
      <a-col :xl="4" :md="12" :xs="24">
        <a-select allowClear placeholder="Publisher" style="width: 100%" :showSearch="true" :filterOption="true"
          optionFilterProp="children" v-model="selectedPublisher" @change="getFilteredZones">
          <a-select-option v-for="obj in ORTBpublisher.list" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name
            }}</a-select-option>
        </a-select>
      </a-col>
      <a-col :xl="4" :md="12" :xs="24">
        <a-select allowClear placeholder="Account Manager" style="width: 100%" :showSearch="true" :filterOption="true"
          optionFilterProp="children" v-model="selectedUser" @change="getFilteredZones">
          <a-select-option v-for="obj in user.list" :key="obj.Id">[{{ obj.Id }}] {{ obj.FirstName }} {{ obj.LastName
            }}</a-select-option>
        </a-select>
      </a-col>
    </a-row>
    <b-row>
      <b-col class="my-1" md="6">
        <b-form-group class="mb-0" label="Per page:" label-cols-sm="2">
          <perPageSelect v-model="perPage" :storageKey="'ortb-pop-feeds'" :pageOptions="pageOptions" />
          <!-- <b-form-select v-model="perPage" :options="pageOptions" class="form-control ml-0 w-25"></b-form-select> -->
          <columnSelect :storageKey="'display-popfeeds-columns'" :options="fields"></columnSelect>
          <CreateButton target="popfeed" />
        </b-form-group>
      </b-col>
      <b-col class="my-1 d-flex flex-row-reverse" md="6">
        <b-form-group class="mb-0">
          <b-input-group>
            <b-form-input v-model="filter" class="form-control w-50" placeholder="Type to Search"></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <!-- Main table element -->
    <b-table :bordered="true" :current-page="currentPage" :fields="filteredFields" :filter="filter" :hover="true"
      :items="showpopfeeds" :per-page="perPage" :small="true" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
      :sort-compare="sortCompare" :sort-direction="sortDirection" :striped="true" :tbody-tr-class="rowClass" show-empty
      stacked="md" @filtered="onFiltered">
      <template #cell(Name)="data">
        <div class="items-center">
          {{ data.item.Name }}
        </div>
      </template>
      <template #cell(PublisherRevShare)="data">
        <div class="items-center">{{ data.item.PublisherRevShare }}%</div>
      </template>
      <template #cell(PublisherName)="data">
        <!-- SM screen -->
        <div class="d-block d-sm-none">
          <!-- name slot -->
          <div class="items-center">
            <div v-if="data.item.PublisherName.length <= 20">{{ data.item.PublisherName }}</div>
            <div class="text-left" style="max-width: 200px; min-width: 140px; word-wrap: break-word;"
              v-if="data.item.PublisherName.length > 20">{{ data.item.PublisherName }}
            </div>
          </div>
        </div>
        <!-- * EXCEPT SM screen -->
        <div class="d-none d-sm-block">
          <!-- name slot -->
          <div class="items-center">
            <div data-toggle="tooltip" data-placement="top" :title="data.item.PublisherName" class="">
              {{ data.item.PublisherName }}
            </div>
          </div>
        </div>
      </template>
      <!-- <template #cell(Rtbzones)="data">
        <div v-if="data.item.Rtbzones.length > 0">
          <a-tooltip slot="addonBefore" trigger="click" :overlayStyle="{ 'max-width': '200%' }">
            <template slot="title">
              <div className="zone-list">
                <div :key="zone.Id" v-for="zone in rtbzones">
                  {{
      data.item.Rtbzones.includes(zone.Id) ? `[${zone.Id}] ${zone.Name}` : ''
    }}
                </div>
              </div>
            </template>
            <a-button type="primary">
              {{ data.item.Rtbzones.length }}
            </a-button>
          </a-tooltip>
        </div>
      </template> -->
      <template #cell(Remotertbzones)="data">
        <div v-if="data.item.Remotertbzones.length > 0">
          <a-tooltip slot="addonBefore" trigger="click" :overlayStyle="{ 'max-width': '200%' }">
            <template slot="title">
              <div className="zone-list">
                <div :key="zone.Id" v-for="zone in remotertbzones">
                  {{
                    data.item.Remotertbzones.includes(zone.Id) ? `[${zone.Id}] ${zone.Name}` : ''
                  }}
                </div>
              </div>
            </template>
            <a-button type="primary">
              {{ data.item.Remotertbzones.length }}
            </a-button>
          </a-tooltip>
        </div>
      </template>
      <template #cell(Dsppopfeeds)="data">
        <div v-if="data.item.Dsppopfeeds.length > 0">
          <a-tooltip slot="addonBefore" trigger="click" :overlayStyle="{ 'max-width': '200%' }">
            <template slot="title">
              <div className="zone-list">
                <div :key="zone.Id" v-for="zone in dsppopfeeds">
                  {{
                    data.item.Dsppopfeeds.includes(zone.Id) ? `[${zone.Id}] ${zone.Name}` : ''
                  }}
                </div>
              </div>
            </template>
            <a-button type="primary">
              {{ data.item.Dsppopfeeds.length }}
            </a-button>
          </a-tooltip>
        </div>
      </template>
      <template #cell(Tmax)="data">
        {{ data.item.Tmax }} ms
      </template>
      <template #cell(StatusString)="data">
        <a-checkbox :checked="(data.item.Status == 0)" @change="togglePopFeed(data.item)">
        </a-checkbox>
      </template>
      <template #cell(Secure)="data">
        {{ data.item.Secure === 1 ? '✔' : '' }}
      </template>
      <template #cell(actions)="data">
        <div class="flex">
          <b-button @click="showModal(data.item)" class="btn-info mr-1" size="sm">
            <i class="fa fa-info"></i>
          </b-button>
          <router-link class="add-button" @click.stop
            :to="{ name: 'popfeed-update', params: { id: data.item.Id }, }">
            <b-button class="btn-info mr-1" size="sm" @click="editPopFeed(data.item.Id)">
              <i class="fa fa-edit"></i>
            </b-button>
          </router-link>
          <b-button class="btn-info mr-1" size="sm" @click="copyPopfeed(data.item.Id, data.item)">
            <i class="fa fa-copy"></i>
          </b-button>
        </div>
      </template>
    </b-table>
    <b-modal id="bv-modal-example" hide-footer size="lg" body-class="rtb-modal" class="rtb-modal">
      <template #modal-title>
        Pop Feed Info
      </template>
      <div class="d-block" style="min-height: 500px">
        <div>
          <a-tabs default-active-key="1" class="mb-5">
            <a-tab-pane key="1" tab="URL Template">
              <div class="card">
                <div class="card-header p-3" style="background-color: rgba(237, 237, 243, 0.38)">
                  Pop Feed URL
                </div>
                <div class="card-body p-3">
                  <a-tooltip placement="bottom">
                    <template slot="title">
                      <span>Copy URL</span>
                    </template>
                    <a-input-search :value="modal.feedUrl" placeholder="input search text" @focus="selectUrl"
                      @change="$event.target.value = modal.feedUrl" @search="copyUrl">
                      <template #enterButton>
                        <a-button @click="copyUrl(modal.feedUrl)"><i class="fa fa-copy"></i></a-button>
                      </template>
                    </a-input-search>
                  </a-tooltip>
                </div>
              </div>
              <div>
                <p class="parameters-title">Parameters</p>
                <div class="parameter">
                  <p class="parameter-key">ip</p>
                  <p class="parameter-value">IP address of the visitor.</p>
                </div>
              </div>
              <div class="parameter">
                <p class="parameter-key">ua</p>
                <p class="parameter-value">User-Agent of the visitor. Must be URL encoded.</p>
              </div>
              <div class="parameter">
                <p class="parameter-key">width</p>
                <p class="parameter-value">Video player width.</p>
              </div>
              <div class="parameter">
                <p class="parameter-key">height</p>
                <p class="parameter-value">Video player height.</p>
              </div>
              <div class="parameter">
                <p class="parameter-key">linearity</p>
                <p class="parameter-value">Type of an ad impression: 1 = Linear, 2 = Overlay.</p>
              </div>
              <div class="parameter">
                <p class="parameter-key">pos</p>
                <p class="parameter-value">Video position on screen: 0 = All, 1 = Above the fold, 3 = Below the fold, 4
                  =
                  Header, 5 = Footer, 6 = Sidebar, 7 = Fullscreen.</p>
              </div>
              <div class="parameter">
                <p class="parameter-key">placement</p>
                <p class="parameter-value">Video placement type: 1 = In-Stream, 2 = In-Banner, 3 = In-Article, 4 =
                  In-Feed, 5 = Interstitial/Slider/Floating.</p>
              </div>
              <div class="parameter">
                <p class="parameter-key">site_url</p>
                <p class="parameter-value">URL of the page where ads will be displayed. Must be URL encoded.</p>
              </div>
              <div class="parameter">
                <p class="parameter-key">app_bundle</p>
                <p class="parameter-value">Application bundle. For example: "com.app.test" for Android apps and
                  "343200656" for iOS apps.</p>
              </div>
              <div class="parameter">
                <p class="parameter-key">app_name</p>
                <p class="parameter-value">Name of the application. Must be URL encoded.</p>
              </div>
              <div class="parameter">
                <p class="parameter-key">app_store_url</p>
                <p class="parameter-value">Store URL of the application. Must be URL encoded.</p>
              </div>
              <div class="parameter">
                <p class="parameter-key">bidfloor</p>
                <p class="parameter-value">Minimal CPM for the request.</p>
              </div>
              <div class="parameter">
                <p class="parameter-key">minduration</p>
                <p class="parameter-value">Minimal Video Ad duration in seconds.</p>
              </div>
              <div class="parameter">
                <p class="parameter-key">maxduration</p>
                <p class="parameter-value">Maximal Video Ad duration in seconds.</p>
              </div>
              <div class="parameter">
                <p class="parameter-key">startdelay</p>
                <p class="parameter-value">Start delay in seconds for pre-roll, mid-roll, or post-roll ad placements: >0
                  =
                  Mid-Roll (value indicates start delay in second), 0 = Pre-Roll, -1 = Generic Mid-Roll, -2 = Generic
                  Post-Roll</p>
              </div>
              <div class="parameter">
                <p class="parameter-key">category</p>
                <p class="parameter-value">Advertising category according to IAB classification.</p>
              </div>
              <div class="parameter">
                <p class="parameter-key">device_type</p>
                <p class="parameter-value">The general type of device according to IAB classification.</p>
              </div>
              <div class="parameter">
                <p class="parameter-key">device_make</p>
                <p class="parameter-value">Device make (e.g., "Apple"). Must be URL encoded.</p>
              </div>
              <div class="parameter">
                <p class="parameter-key">device_model</p>
                <p class="parameter-value">Device model (e.g., "iPhone"). Must be URL encoded.</p>
              </div>
              <div class="parameter">
                <p class="parameter-key">os</p>
                <p class="parameter-value">Device operating system (e.g., "iOS"). Must be URL encoded.</p>
              </div>
              <div class="parameter">
                <p class="parameter-key">osv</p>
                <p class="parameter-value">Device operating system version (e.g., "17"). Must be URL encoded.</p>
              </div>
              <div class="parameter">
                <p class="parameter-key">lang</p>
                <p class="parameter-value">Language using ISO-639-1-alpha-2 (e.g., "en").</p>
              </div>
              <div class="parameter">
                <p class="parameter-key">ifa</p>
                <p class="parameter-value">Identifier for Advertisers. Must be URL encoded.</p>
              </div>
              <div class="parameter">
                <p class="parameter-key">paln</p>
                <p class="parameter-value">PALN. Must be URL encoded.</p>
              </div>
              <div class="parameter">
                <p class="parameter-key">givn</p>
                <p class="parameter-value">GIVN. Must be URL encoded.</p>
              </div>
              <div class="parameter">
                <p class="parameter-key">cb</p>
                <p class="parameter-value">Cache buster: a uniquely generated character sequence for each link in order to prevent page caching.</p>
              </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="Publisher Reports">
              <div class="card mb-2">
                <div class="card-header p-3" style="background-color: rgba(237, 237, 243, 0.38)">
                  By Date
                </div>
                <div class="card-body p-3">
                  <a-input-search :value="modal.reportsByDate" placeholder="" @focus="selectUrl"
                    @change="$event.target.value = modal.reportsByDate" @search="copyUrl">
                    <template #enterButton>
                      <a-button @click="copyUrl(modal.reportsByDate)"><i class="fa fa-copy"></i></a-button>
                    </template>
                  </a-input-search>
                </div>
              </div>
            </a-tab-pane>
          </a-tabs>
        </div>
      </div>
    </b-modal>

    <b-row>
      <b-col class="my-1" md="6">
        <p>Showing {{ ((currentPage - 1) * perPage).toLocaleString() }} to {{
      (((currentPage - 1) * perPage) + perPage > totalRows ? totalRows : ((currentPage - 1) * perPage) +
        perPage).toLocaleString()
    }} of {{ parseInt(totalRows).toLocaleString() }} entries</p>
      </b-col>
      <b-col class="my-1 d-flex flex-row-reverse" md="6">
        <b-pagination v-model="currentPage" :per-page="perPage" :total-rows="totalRows" class="my-0"></b-pagination>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import columnSelect from '@/components/custom/tableColumnSelect'
import { Modal } from 'ant-design-vue'
import { clonePopFeed } from '@/api/display/popfeed'
import perPageSelect from '@/components/custom/PerPageSelect'
import { getRemoteRTBZonesShort } from '@/api/display/remotertbzone'
import { getDspPopFeedsShort } from '@/api/display/dsppopfeed'

export default {
  components: {
    columnSelect,
    CreateButton: () => import('@/components/custom/create-button/index.vue'),
    perPageSelect,
  },
  data() {
    return {
      selectedPublisher: [],
      selectedUser: [],
      days: [
        {
          title: 'Saturday',
          shortcut: 'sat',
        },
        {
          title: 'Sunday',
          shortcut: 'sun',
        },
        {
          title: 'Monday',
          shortcut: 'mon',
        },
      ],
      selectedDays: ['sun'],
      fields: [
        {
          key: 'actions',
          label: 'Actions',
          show: true,
          headerTitle: 'Actions',
          class: 'text-center',
          thStyle: 'width:120px',
        },
        {
          key: 'Id',
          label: 'Feed ID',
          show: true,
          headerTitle: 'Feed ID',
          sortable: true,
          thStyle: 'width:80px',
          tdClass: 'text-right',
        },
        {
          key: 'Name',
          label: 'Feed Name',
          show: true,
          headerTitle: 'Feed Name',
          sortable: true,
        },
        {
          key: 'PublisherName',
          label: 'Publisher',
          show: true,
          headerTitle: 'Publisher Name',
          sortable: true,
          thStyle: 'width:180px',
        },
        {
          key: 'PublisherRevShare',
          label: 'Rev. Share',
          show: true,
          headerTitle: 'Publisher Revenue Share',
          sortable: true,
          thClass: 'text-center',
          thStyle: 'width:100px',
          tdClass: 'text-md-right text-center',
        },
        {
          key: 'Remotertbzones',
          label: 'Remote RTB Zones',
          show: true,
          headerTitle: 'Remote RTB Zones',
          sortable: true,
          tdClass: 'text-center',
          thStyle: 'width: 150px',
        },
        {
          key: 'Dsppopfeeds',
          label: 'DSP Pop Feeds',
          show: true,
          headerTitle: 'DSP Pop Feeds',
          sortable: true,
          tdClass: 'text-center',
          thStyle: 'width: 120px',
        },
        {
          key: 'RequestsDailyLimit',
          label: 'Requests Daily Limit',
          headerTitle: 'Requests Daily Limit',
          show: false,
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
          formatter: '$formatNumber',
          thStyle: 'width:160px',
          summed: false,
        },
        {
          key: 'BidsDailyLimit',
          label: 'Bids Daily Limit',
          headerTitle: 'Bids Daily Limit',
          show: false,
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
          formatter: '$formatNumber',
          thStyle: 'width:130px',
          summed: false,
        },
        {
          key: 'ImpressionsDailyLimit',
          label: 'Imp. Daily Limit',
          headerTitle: 'Impressions Daily Limit',
          show: false,
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
          formatter: '$formatNumber',
          thStyle: 'width:130px',
          summed: false,
        },
        {
          key: 'SpentDailyLimit',
          label: 'Spent Daily Limit',
          headerTitle: 'Spent Daily Limit',
          show: false,
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
          formatter: '$formatCurrency',
          thStyle: 'width:150px',
          summed: false,
        },
        {
          key: 'Bidfloor',
          label: 'Bidfloor',
          headerTitle: 'Bidfloor',
          show: true,
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-md-right text-center',
          formatter: '$formatCurrency',
          thStyle: 'width:80px',
        },
        {
          key: 'Tmax',
          label: 'Tmax',
          headerTitle: 'Tmax',
          show: true,
          sortable: true,
          class: 'text-center',
          thStyle: 'width:80px',
          tdClass: 'text-md-right text-center',
        },
        {
          key: 'Secure',
          label: 'Secure',
          show: true,
          headerTitle: 'Secure',
          sortable: true,
          class: 'text-center',
          thStyle: 'width:85px',
        },
        {
          key: 'Qps',
          label: 'QPS',
          show: true,
          headerTitle: 'Queries per second',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
          thStyle: 'width:70px',
        },
        {
          key: 'AccManagerId',
          label: 'Acc. Mngr. ID',
          show: false,
          headerTitle: 'Account Manager ID',
          sortable: true,
          thStyle: 'width:110px',
          tdClass: 'text-right',
        },
        {
          key: 'AccManagerName',
          label: 'Account Manager',
          show: true,
          headerTitle: 'Account Manager Name',
          sortable: true,
        },
        {
          key: 'StatusString',
          label: 'Active',
          show: true,
          class: 'text-center',
          thStyle: 'width:80px',
        },
      ],
      remotertbzones: null,
      dsppopfeeds: null,
      currentPage: 1,
      perPage: 50,
      pageOptions: [20, 50, 100, 200],
      sortBy: null,
      sortDesc: false,
      sortDirection: 'desc',
      filter: null,
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      modal: {
        feedUrl: '',
        reportsByDate: '',
        reportsBySubid: '',
        reportsByCountry: '',
      },
    }
  },
  computed: {
    ...mapState(['popfeed', 'ORTBpublisher', 'settings', 'user', 'rtbzone']),
    ...mapGetters('popfeed', ['getPopFeeds', 'getActivePopFeeds']),
    showActive: {
      get: function () {
        return this.settings.showActivePopFeeds
      },
      set: function (value) {
        this.$store.commit('CHANGE_SETTING', {
          setting: 'showActivePopFeeds',
          value,
        })
      },
    },
    showpopfeeds() {
      return this.showActive ? this.getActivePopFeeds : this.getPopFeeds
    },
    filteredFields() {
      return this.fields.filter(c => c.show)
    },
    totalRows() {
      return this.showpopfeeds.length
    },
  },
  methods: {
    showModal(item) {
      this.$bvModal.show('bv-modal-example')
      this.modal.feedUrl = item.FeedUrl
      this.modal.reportsByDate = item.StatsByDate
      this.modal.reportsBySubid = item.StatsBySubid
      this.modal.reportsByCountry = item.StatsByCountry
    },
    selectUrl(e) {
      e.target.select()
      e.stopPropagation()
    },
    copyUrl(link) {
      navigator.clipboard.writeText(link)
    },
    sortCompare(a, b, key) {
      if (key === 'Rtbzones' || key === 'Videozones' || key === 'Bannerzones') {
        const lenA = Array.isArray(a[key]) ? a[key].length : 0
        const lenB = Array.isArray(b[key]) ? b[key].length : 0
        return lenA - lenB
      }
      return a[key] > b[key] ? 1 : (a[key] < b[key] ? -1 : 0)
    },
    getPublishers() {
      window.localStorage.setItem('pop-feed-publisher-key', JSON.stringify(this.selectedPublisher))
      this.$store.dispatch('popfeed/LOAD_FEEDS_FOR_PUBLISHER', { PublisherId: this.selectedPublisher })
    },
    getFilteredZones() {
      if (this.selectedPublisher !== []) {
        window.localStorage.setItem('pop-feed-publisher-key', JSON.stringify(this.selectedpublisher))
      }
      if (this.selectedUser !== []) {
        window.localStorage.setItem('pop-feed-user-key', JSON.stringify(this.selectedUser))
      }
      this.$store.dispatch('popfeed/LOAD_FEEDS_FOR_USER_ADV', { AccManagerId: this.selectedUser, PublisherId: this.selectedPublisher })
    },
    fetchPopFeeds(id) {
      clonePopFeed(id).then(response => {
        if (response) {
          // this.$router.push('/display/remotertbzone/update/' + response.Remotertbzone.Id)
          this.$router.push({
            name: 'popfeed-update',
            params: { id: response.Popfeed.Id },
          }).catch(() => {
          })
          this.$notification.success({
            message: 'Pop Feed cloned',
            description: `Pop Feed: ${response.Popfeed.Name} has been successfully cloned.`,
          })
        }
      }).catch(error => console.log(error))
    },
    copyPopfeed(id, ad) {
      Modal.confirm({
        title: 'Are you sure you want to clone Pop Feed?',
        content: h => <div>{status} Clone Pop Feed: <strong>{ad.Name}</strong>?</div>,
        onOk: function () {
          this.fetchPopFeeds(id)
        }.bind(this),
      })
    },
    rowClass(item, type) {
      if (item != null && item.Status === 1) return 'text-muted'
    },
    editPopFeed(id) {
      if (this.selectedPublisher && this.selectedPublisher !== []) {
        window.localStorage.setItem('pop-feed-publisher-key', JSON.stringify(this.selectedPublisher))
        if (window.localStorage.getItem('pop-feed-user-key')) {
          window.localStorage.setItem('pop-feed-user-key', JSON.stringify(this.selectedUser))
        }
      }
      // this.$router.push('/display/remotertbzone/update/' + id)
      this.$router.push({ name: 'popfeed-update', params: { id: id } }).catch(() => { })
    },
    togglePopFeed(popfeed) {
      const status = popfeed.Status === 0 ? 'Deactivate' : 'Activate'
      Modal.confirm({
        title: 'Are you sure you want to change Pop Feed status?',
        content: h => <div>{status} Pop Feed <strong>{popfeed.Name}</strong>?</div>,
        onOk: function () {
          const newStatus = popfeed.Status === 0 ? 1 : 0
          this.$store.dispatch('popfeed/CHANGE_STATUS', {
            id: popfeed.Id,
            status: newStatus,
          })
        }.bind(this),
      })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
  created() {
    if ((window.localStorage.getItem('pop-feed-publisher-key')) !== null || window.localStorage.getItem('pop-feed-user-key') !== null) {
      this.$store.dispatch('popfeed/LOAD_FEEDS_FOR_USER_ADV', { PublisherId: parseInt(window.localStorage.getItem('pop-feed-publisher-key')) || [], AccManagerId: parseInt(window.localStorage.getItem('pop-feed-user-key')) || [] })
    } else {
      this.$store.dispatch('popfeed/LOAD_FEEDS')
      // console.log(this.popfeed, 'POP')
    }
    this.$store.dispatch('ORTBpublisher/LOAD_PUBLISHERS_SHORT')
    this.$store.dispatch('user/LOAD_USERS')
      .then(() => {
        if (window.localStorage.getItem('pop-feed-publisher-key')) {
          const filterPublisher = parseInt(window.localStorage.getItem('pop-feed-publisher-key'))
          if (filterPublisher !== [] && !isNaN(filterPublisher)) {
            this.selectedPublisher = filterPublisher
          }
          window.localStorage.removeItem('pop-feed-publisher-key')
        }
        if (window.localStorage.getItem('pop-feed-user-key')) {
          const filterUser = parseInt(window.localStorage.getItem('pop-feed-user-key'))
          if (filterUser !== [] && filterUser) {
            this.selectedUser = filterUser
          }
          window.localStorage.removeItem('pop-feed-user-key')
        }
      })
    getRemoteRTBZonesShort()
      .then(res => {
        this.remotertbzones = res
      })
    getDspPopFeedsShort()
      .then(res => {
        this.dsppopfeeds = res
      })
  },
  // mounted() {
  //   if (window.localStorage.getItem('pop-feed-publisher-key')) {
  //     const filterPublisher = parseInt(window.localStorage.getItem('pop-feed-publisher-key'))
  //     this.selectedPublisher = filterPublisher
  //     window.localStorage.removeItem('pop-feed-publisher-key')
  //   }
  // },
}
</script>

<style scoped>
.zone-list {
  display: flex;
  flex-direction: column;
}
.rtb-modal {
  color: red;
}

.parameter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.parameter-key {
  width: 20%;
  font-weight: 600;
}

.parameter-value {
  width: 80%;
  text-align: left;
}

.parameters-title {
  font-size: 140%;
  font-style: bold;
}

@media only screen and (max-width: 992px) {
  .parameter-value {
    width: 75%;
    text-align: left;
  }
}
</style>
